var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-between mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column justify-md-start justify-space-between pt-2",attrs:{"cols":"6"}},[_c('h2',[_vm._v(_vm._s(_vm.$route.params.parameterName))]),_c('h4',[_vm._v(_vm._s(_vm.$_strings.parameter.PARAMETER_FOR)+" "+_vm._s(_vm.$route.params.parameterGroupName))])]),_c('v-col',{staticClass:"d-flex justify-end align-center pa-2",attrs:{"md":"3","cols":"12"}},[_c('v-text-field',{ref:"searchParamName",staticClass:"display-2 caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.parameter.PLACEHOLDER_PARAMETER_NAME},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.paramName),callback:function ($$v) {_vm.paramName=$$v},expression:"paramName"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.itemsTable,"sort-by":"calories","item-key":"id","server-items-length":_vm.parameterListTotalEntry,"options":_vm.pagination,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      disablePagination: _vm.isLoading,
      disableItemsPerPage: _vm.isLoading,
      'items-per-page-options': _vm.$_item_per_page
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.EDIT)+" ")]):_vm._e()]}},{key:"item.paramName",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.parameterName))])]}},{key:"item.paramValue",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.parameterValue))])]}},{key:"item.paramCode",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.parameterCode))])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.parameter.DATABASE_NAME)+" "),(_vm.itemsTable.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }