<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col>
        <h4 class="ml-4">{{form.parameterName && `Ubah Force Update ${form.parameterName}`}}</h4>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="form"
    >
      <v-card outlined class="pa-4">
        <v-row>
          <v-col cols="12" sm="3" md="2">
            <span>{{$_strings.parameter.headers.PARAMETER_NAME}}</span>
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field
              height="40"
              class="input-disabled"
              :value="form.parameterCode"
              solo
              disabled
              hide-details
              aria-hidden="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pt-3">
          <v-col class="pb-0" cols="12" sm="3" md="2">
            <span>{{$_strings.parameter.headers.VALUE}}</span>
          </v-col>
          <v-col class="pb-0" cols="12" sm="7">
            <v-text-field
              v-model="form.parameterValue"
              :rules="rules.parameterValueRules"
              height="40"
              placeholder="Input Value"
              solo
              requried
              aria-hidden="true"
              v-if="!form.isHasParameter"
            ></v-text-field>
            <v-textarea
              v-model="form.parameterValue"
              :rules="rules.parameterValueRules"
              placeholder="Input Value"
              solo
              required
              rows="3"
              v-if="form.isHasParameter"
            ></v-textarea>
            <p style="margin-top: -24px;" v-if="form.isHasParameter">*Note : Variable yang diambil dari database hanya yang berada dalam kurung {{$_strings.common.CURLY}}. Perhatikan kembali penulisan variable dan pastikan sudah benar.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" sm="3" md="2">
            <span>{{$_strings.parameter.headers.DESCRIPTION}}</span>
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field
              :value="form.parameterName"
              height="40"
              class="input-disabled"
              placeholder="Input Keterangan"
              solo
              disabled
              aria-hidden="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12" sm="9" class="d-flex justify-end">
            <v-btn color="red" class="white--text mr-3" @click="$router.back()">{{$_strings.common.CANCEL}}</v-btn>
            <v-btn @click="submit" class="primary">{{$_strings.common.SAVE}}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'parameter-edit-page',
  data() {
    return {
      valid: true,
      form: {
        appParamGroupId: '',
        appParamId: '',
        parameterName: '',
        parameterCode: '',
        parameterValue: '',
        expiredOn: null,
        active: true,
      },
      rules: {
        parameterNameRules: [
          (v) => !!v || 'Parameter wajib diisi',
        ],
        parameterCodeRules: [
          (v) => !!v || 'Parameter code wajib diisi',
        ],
        parameterValueRules: [
          (v) => !!v || 'Parameter value wajib diisi',
        ],
        dateRules: [
          (v) => !!v || 'Tanggal wajib diisi',
        ],
        timeRules: [
          (v) => !!v || 'Jam wajib diisi',
        ],
        descRules: [
          (v) => !!v || 'Keterangan wajib diisi',
        ],
      },
    };
  },
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.form = {
        ...this.form,
        ...data,
      };
      return;
    }
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const { parameterGroupId, parameterId } = this.$route.params;
      try {
        this.$root.$loading.show();
        const result = await this.$_services.parameter.fetchParameterDetail({
          parameterGroupId,
          parameterId,
        });
        this.form = {
          ...this.form,
          ...result.data,
        };
      } finally {
        this.$root.$loading.hide();
      }
    },
    submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.form.appParamId = this.$route.params.parameterId;
        this.form.appParamGroupId = this.$route.params.parameterGroupId;
        this.$root.$loading.show();
        this.$_services.parameter.updateParameter(this.form)
          .then(() => {
            this.$router.go(-1);
          }).finally((i) => {
            this.$root.$loading.hide();
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  ::v-deep {
    .form {
      .v-text-field.v-text-field--solo .v-input__control {
          min-height: 0;
          padding: 0;
          border-radius: 0;
      }
      .input-disabled.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
        background: $--color-bg-lightgray;
      }
      .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
          box-shadow: none !important;
      }
    }
  }
</style>
