<template>
  <v-container fluid>
    <v-row class="d-flex justify-space-between mb-2" no-gutters>
      <v-col class="d-flex flex-column justify-md-start justify-space-between pt-2" cols="6">
        <h2>{{$route.params.parameterName}}</h2>
        <h4>{{$_strings.parameter.PARAMETER_FOR}} {{$route.params.parameterGroupName}}</h4>
      </v-col>
      <v-col class="d-flex justify-end align-center pa-2" md="3" cols="12">
        <v-text-field
          dense
          hide-details
          outlined
          class="display-2 caption"
          ref="searchParamName"
          :placeholder="$_strings.parameter.PLACEHOLDER_PARAMETER_NAME"
          v-model="paramName"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="itemsTable"
      sort-by="calories"
      class="elevation-1 table"
      item-key="id"
      :server-items-length="parameterListTotalEntry"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        disablePagination: isLoading,
        disableItemsPerPage: isLoading,
        'items-per-page-options': $_item_per_page
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="edit(item)"
          color="primary"
          small
          v-if="userAccess.canUpdate"
        >
          {{$_strings.common.EDIT}}
        </v-btn>
      </template>
      <template v-slot:[`item.paramName`]=items>
        <span>{{ items.item.parameterName }}</span>
      </template>
      <template v-slot:[`item.paramValue`]=items>
        <span>{{ items.item.parameterValue }}</span>
      </template>
      <template v-slot:[`item.paramCode`]=items>
        <span>{{ items.item.parameterCode }}</span>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          {{$_strings.parameter.DATABASE_NAME}}
          <span v-if="itemsTable.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>

import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';

export default {
  name: 'parameter-masterdata-page',
  data() {
    return {
      isLoading: false,
      paramName: this.$route.query.paramName || '',
      parameterGroupId: '',
      headers: [
        { text: this.$_strings.parameter.headers.PARAMETER_NAME, value: 'paramCode' },
        { text: this.$_strings.parameter.headers.VALUE, value: 'paramValue' },
        { text: this.$_strings.parameter.headers.DESCRIPTION, value: 'paramName' },
        { value: 'actions', sortable: false },
      ],
      itemsTable: [],
      pagination: this.defaultPagination(),
      parameterListTotalEntry: 0,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    search() {
      if (this.paramName !== this.$route.query.paramName) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            paramName: this.paramName,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchData();
    },
    fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const { parameterGroupId } = this.$route.params;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        paramName: this.paramName ? `qLike(${this.paramName})` : '',
        sort: this.handleSortBy({ sortBy, sortDesc }),
      };
      this.isLoading = true;
      this.$_services.parameter.fetchParameterList(this.skipEmptyStringObject(filters), parameterGroupId)
        .then((result) => {
          const resultData = result.data.contents.map((e) => {
            const data = {
              ...e,
              active: e.active ? 'Ya' : 'Tidak',
              expiredOn: this.dateFormat(e.expiredOn),
            };
            return data;
          });
          this.itemsTable = resultData;
          this.parameterListTotalEntry = result.data.totalData;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
    dateFormat(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    async edit(item) {
      const { parameterGroupId } = this.$route.params;
      try {
        this.$root.$loading.show();
        const result = await this.$_services.parameter.fetchParameterDetail({
          parameterGroupId,
          parameterId: item.id,
        });
        this.$router.push({
          name: 'edit-parameter',
          params: {
            parameterGroupId,
            parameterId: item.id,
            data: result.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
