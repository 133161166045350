<template>
  <v-row class="w-100 w-full" justify="center">
    <v-col cols="12" sm="6" md="5" lg="4">
      <v-sheet
        color="white"
        elevation="4"
        outlined
        rounded
        class="ma-5 pa-6 rounded-lg"
      >
        <div class="text-center">
          <p class="body-2 description"></p>
        </div>
        <v-btn rounded large class="background-denim white--text font-weight-bold body-2 w-100 mt-15" @click="shipperRegistration">
          {{$_strings.registration.button.SHIPPER_REGISTRATION}}
        </v-btn>
        <v-btn  rounded outlined large class="text-denim mt-5 body-2 w-100 font-weight-bold" @click="transporterRegistration">
          {{$_strings.registration.button.TRANSPORTER_REGISTRATION}}
        </v-btn>
        <div class="text-center ma-0 mt-15 justify-center grey--text">
          <p class="caption" >{{$_strings.registration.label.INFO}}</p>
        </div>
      </v-sheet>
    </v-col>
    <v-footer class="d-flex justify-end">
      <p>Copyright &copy; 2019 - {{yearNow}} <a target="_blank" href="http://">fastlog.id</a>, All Rights Reserved. </p>
    </v-footer>
  </v-row>
</template>

<script>

export default {
  name: 'form-registration',
  data() {
    return {
      yearNow: new Date().getFullYear(),
    };
  },
  mounted() {
    const description = document.getElementsByClassName('description')[0];
    description.innerHTML = this.$_strings.registration.label.DESCRIPTION;
  },
  methods: {
    shipperRegistration() {
      const url = window.location.origin;
      if (process.env.VUE_APP_API_TARGET === 'production') {
        return window.open('https://shp.fastlog.id/welcome/registration');
      }
      if (process.env.VUE_APP_API_TARGET === 'staging') {
        if (/v3/g.test(url)) {
          return window.open('https://shipper-betav3.fastlog.id/welcome/registration');
        }
        return window.open('https://shipper-beta.fastlog.id/welcome/registration');
      }
      return window.open('https://shipper-dev.fastlog.id/welcome/registration');
    },
    transporterRegistration() {
      const url = window.location.origin;
      if (process.env.VUE_APP_API_TARGET === 'production') {
        window.open('https://3pl.fastlog.id/welcome/registration');
      }
      if (process.env.VUE_APP_API_TARGET === 'staging') {
        if (/v3/g.test(url)) {
          return window.open('https://transporter-betav3.fastlog.id/welcome/registration');
        }
        return window.open('https://transporter-beta.fastlog.id/welcome/registration');
      }
      return window.open('https://transporter-dev.fastlog.id/welcome/registration');
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .title{
    color: $--color-doveGray;
    font-size: 16px !important;
  }
  .v-footer {
    background: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
